@import '~app/mixins';

.postcard {
  width: 100%;
  overflow-y: auto;

  :global {
    .fr-box {
      height: 250px;
    }

    .fr-box .fr-wrapper {
      transform: scale(0.25);
      transform-origin: top left;
      width: 400%;
      height: 800px;
      min-height: 800px;
    }
  }
}

.textEditors {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .textEditor {
    width: 49%;
  }
}

button {
  + button {
    margin-left: 10px;
  }
}

.content {
  .contentInner {
    > div {
      width: 1000px;
      display: flex;
      justify-content: space-between;

      > div {
        width: 490px;
      }
    }
    background-color: #fff !important;
  }

  .sides {
    .side {
      + .side {
        margin-left: 15px;
      }

      .graphicContainer {
        box-shadow: 2px 2px 5px grey;
        text-align: center;
        position: relative;

        > div {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }

        .buttons {
          flex-direction: column;
          justify-content: center;
          background-color: rgba(128, 128, 128, 0.5);
          display: none;

          .selectPhoto {
            padding: 5px 0;
            color: #6b7581;
            font-size: 12px;
            line-height: 18px;
            font-style: italic;
          }

          .iconPicture {
            height: 18px;
            width: 24px;

            use {
              @include primary-fill-color();
            }
          }

          .selectColor {
            margin-top: 10px;
          }

          .button,
          button {
            min-width: 125px;
          }
        }

        &.empty,
        &:hover {
          .buttons {
            display: flex;
          }
        }
      }

      &Header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      .link {
        padding: 0;
      }

      .title { margin-bottom: 0; }
    }
  }

  .photo {
    background: #d1d1d1;
    width: 100%;
    min-height: 200px;
    text-align: center;
    padding: 25px;
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .title {
    color: $color-grey;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .header {
    border: 1px solid $color-filter-border;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  .headerText {
    font-size: 13px;
    line-height: 1.42;
    width: 100%;
    padding: 12px 15px;
    border: none;
  }

  .browseLogo {
    margin-top: 0;
  }

  .caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    margin-top: 15px;

    &Text {
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      color: $color-grey;
    }

    &Note {
      font-size: 11px;
      font-style: italic;
      color: $color-grey-1;

      &.red {
        // todo replace this red to error color
        color: red;
      }
    }
  }
}

.previewHeader {
  font-size: 16px;
  padding-bottom: 8px;
  color: #333;
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

.preview {
  margin-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;

  .header {
    padding: 0 15px;
  }

  .instructions {
    color: $color-grey;
    margin-bottom: 20px;
  }

  .imageContainer {
    display: flex;

    .side {
      + .side { margin-left: 10px; }

      .sideHeader {
        margin-bottom: 5px;
        font-size: 15px;
      }

      img {
        width: 99%;
        box-shadow: 2px 2px 5px grey;
      }
    }
  }

  .disclaimer {
    width: 90%;
    margin: 20px auto;

    .important {
      font-weight: bold;
      color: $color-red;
      margin-right: 10px;
    }

    .message {
      color: $color-grey;
      margin: 10px 0;

      div {
        &.bold {
          font-weight: bold;
          padding: 8px 35px;
          background: url('assets/images/small_red_check.png') no-repeat left center;
        }
      }
    }

    .info {
      color: $color-grey;
      margin-bottom: 8px;
    }
  }

  .buttons {
    text-align: center;
    margin-top: 15px;
    display: flex;
    justify-content: center;

    button {
      min-width: 100px;
    }

    div {
      button:first-child {
        margin-right: 10px;
      }
    }
  }

  &.confirm {
    .buttons {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
}

.settings,
.templates {
  .options {
    margin-top: 15px;

    .option {
      margin-bottom: 20px;
    }

    .inlineRadio {
      display: flex;

      > div {
        margin-right: 10px;

        &:first-child {
          width: 245px;
        }

        &:last-child {
          margin-right: 0;
        }

        .amount {
          @include primary-color();
        }
      }
    }
  }

  .buttons {
    text-align: center;
    margin-top: 20px;
  }

  button {
    width: 100px;
    margin: 0 8px;
  }
}

.settings {
  .label {
    font-weight: bold;
    color: $color-grey;
    font-size: 14px;
  }

  .pricing {
    margin: 15px 30px 30px;
    position: relative;
    box-shadow: 5px 3px 44px rgba(0, 0, 0, 0.16);
    padding-bottom: 20px;

    .priceHeader {
      svg {
        height: 40px;
        width: 40px;
        margin-bottom: -4px;
      }

      &:last-child svg {
        height: 54px;
        width: 54px;
      }
    }

    .option {
      width: 450px;
      margin: 16px auto;

      .size {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
          width: 130px;
        }
      }

      > .label {
        margin-bottom: 5px;
      }

      .design {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  .templateWarning {
    color: red;
    font-size: 13px;
    font-style: italic;
    margin: 5px 25px;
  }

  .images {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    .image {
      width: 48%;

      img {
        display: block;
        max-width: 100%;
        box-shadow: 1px 1px 3px #999;
      }
    }

    &.clickable .image {
      cursor: pointer;
    }
  }

  .deliveries {
    .delivery {
      display: flex;
      margin-top: 6px;

      input[type="text"] {
        height: auto;
        width: 120px;
        padding: 1px 8px;

        &.quantity {
          width: 106px;
        }
      }

      label {
        margin: 4px 6px 0 15px;
      }

      label:first-child {
        width: 110px;
        text-align: right;
      }

      .remove {
        color: $color-red;
        margin: 5px 0 0 8px;
        display: none;
        font-size: 12px;
        cursor: pointer;
      }

      &:hover {
        .remove {
          display: unset;
        }
      }
    }
  }
}

.templates {
  .items {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 20px;
    overflow-y: auto;
    max-height: 575px;
  }

  .item {
    cursor: pointer;
    padding: 5px;
    border: 2px solid transparent;
    border-radius: 5px;
    margin: 0 2px;

    .itemCaption {
      margin-top: 6px;
      color: $color-grey;
      font-size: 13px;
      text-align: center;
    }

    .template {
      width: 160px;
    }

    img {
      display: block;
      max-width: 100%;

      + img {
        margin-top: 4px;
      }
    }

    &.selected {
      @include primary-border-color();

      .itemCaption {
        font-weight: bold;
      }
    }
  }
}

.addressOverlay {
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1200px;
  height: 937px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: black;
  text-align: left;

  .return {
    position: absolute;
    left: 50px;
    top: 50px;
  }

  .address {
    font-size: 60px;
    position: absolute;
    left: 100px;
    top: 350px;
  }

  .endicia {
    position: absolute;
    right: 80px;
    top: 40px;
  }

  &.lob1 {
    width: 1068px;
    height: 790px;
  }

  &.lob2 {
    width: 1276px;
    height: 788px;
  }
}

